$borderColor: #BDBDBD;
$optimalLevelStick: #054A91;
$labelColor: #787878;
$textColor: #333;
$mainBlue: #3276b1;
$darkBlue: #2c699d;
$placeholderColor: #999999;
$backgroundContainerColor: #F9F9F9;
$backgroundDarkColor: #f2f2f2;
$white: #fff;
$barColors: #054A91,#3E7CB1, #FF0054;
$donutColors: #81A4CD, #E8E8E8;
$donutColorsPublication: #3E7CB1, #E8E8E8;
$donutColorsMonograph: #054A91, #E8E8E8;
$dotsColors: #81A4CD, #FF9100;
$barTotalColor: #3E7CB1;
$boxShadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
$breakpointLarge: 'min-width: 992px';
$breakpointMedium: 'min-width: 768px';
$breakpointSmall: 'min-width: 576px';
$errorColor: #c80000;