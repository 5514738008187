@import './variables';

@mixin container {
  margin: 10px 0;
  border: 1px solid $borderColor;
  padding: 10px;
  background: $backgroundContainerColor;
}

@mixin selectContainer {
  @include container;
  [class$="control"] {
    border-radius: 0 !important;
  }
}

@mixin grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
}

@mixin flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin customLabelPie {
  position: absolute;
  top: 60px;
  left: 0;
  display: inline-block;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  color: $labelColor;
  text-align: center;
}

@mixin transition($property) {
  -webkit-transition: $property 0.3s;
  -o-transition: $property 0.3s;
  -moz-transition: $property 0.3s;
  transition: $property 0.3s;
}